<template>
    <div>
        <card-header title="Day Details" icon="fa-calendar-day"/>
        <card-toolbar>
            <button @click="$openCard('new-custom-event', {registrationId: props.registrationId, dayId: props.dayId}, card)"><i class="fas fa-plus mr-2"/>New Custom Event</button>
        </card-toolbar>
        <card-body>
            <img alt="day" v-if="day.photo" :src="apiUrl + 'api/day_photos/img/' + day.photo.file + '/day'">
            <card-list>
                <day-list-item :card="card" :day="day"/>
                <subheader-list-item title="Events" icon="fa-users"/>

                <template v-for="event in events">
                    <custom-event-list-item
                            :card="card"
                            :event="event"
                            v-if="event.type === 'custom_event'"
                            :active="(child && child.definition.component === 'edit-custom-event') && child.props.eventId === event.id.toString()"
                            @click="$openCard('edit-custom-event', {...props, eventId: event.id}, card)"
                    />
                    <flight-list-item
                            :flight="event.flight"
                            :card="card"
                            v-else-if="event.type === 'flight'"
                            :active="(child && child.definition.component === 'edit-flight') && child.props.flightId === event.id.toString()"
                            @click="$openCard('edit-flight', {...props, flightId: event.id}, card)"
                            :itinerary="true"
                    />
                    <reg-event-list-item
                            :card="card"
                            :event="event"
                            v-else-if="event.type === 'override_event'"
                            :active="(child && child.definition.component === 'edit-override-event') && child.props.eventId === event.id.toString()"
                            @click="$openCard('edit-override-event', {...props, eventId: event.id}, card)"
                    />
                    <reg-event-list-item
                            :card="card"
                            :event="event"
                            v-else
                            :active="(child && child.definition.component === 'new-override-event') && child.props.eventId === event.id.toString()"
                            @click="$openCard('new-override-event', {...props, eventId: event.id}, card)"
                    />
                </template>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardList from '../../TIER/components/CardList';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import SubheaderListItem from '../../TIER/components/SubheaderListItem';
    import TripListItem from '../../components/TripListItem';
    import CardBody from '../../TIER/components/CardBody';
    import DayListItem from '../../components/DayListItem';
    import EventListItem from '../../components/EventListItem';

    import {client as http} from '../../http_client';
    import async from 'async';

    import CustomEventListItem from "../../components/CustomEventListItem";
    import FlightListItem from "../../components/FlightListItem";
    import RegEventListItem from "../../components/RegEventListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {RegEventListItem, FlightListItem, CustomEventListItem, EventListItem, DayListItem, CardBody, TripListItem, SubheaderListItem, CardToolbar, CardList, CardHeader},
        data: function () {
            return {
                events: [],
                day: {},
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/',
            };
        },
        methods: {
            loadEvents: function (force) {
                this.$emit('loading', true);
                async.parallel([
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/events/' + this.props.dayId, {force}).then(response => {
                            this.events = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/days/' + this.props.dayId, {force}).then(response => {
                            this.day = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            reload: function () {
                this.loadEvents(true);
            }
        },
        watch: {
            'props.dayId': function () {
                this.loadEvents();
            }
        },
        mounted() {
            this.loadEvents();
        }
    };
</script>

<style lang="scss" scoped>

</style>
